var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v(" 10. Пользование лифтами во время пожара ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-title',{staticClass:"red--text"},[_vm._v(" КАТЕГОРИЧЕСКИ ЗАПРЕЩАЕТСЯ пользоваться "),_c('br'),_vm._v(" лифтом во время пожара! ")]),_c('v-card',{staticClass:"d-flex flex-column justify-center",attrs:{"flat":""}},[_c('v-img',{staticClass:"jystify-center",attrs:{"lazy-src":"img/material/fire-rules-elevator.jpeg","max-width":"70%","max-height":"100%","src":"img/material/fire-rules-elevator.jpeg"}}),_c('v-card-title',[_vm._v(" ГОСТ 34442-2018 (EN 81-73:2016) "),_c('br'),_vm._v("Лифты. Пожарная безопасность. ")]),_c('v-card-text',{staticClass:"title font-weight-light"},[_vm._v(" содержит актуальные требования ПБ к лифтам и информацию, касающуюся грузоподъемных устройств. ")])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-title',{staticClass:"mb-10"},[_vm._v(" Опасности использования лифтов при пожаре: ")]),_c('v-card',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-img',{staticClass:"mr-10",attrs:{"lazy-src":"img/material/elevator-p.svg","min-width":"180%","max-height":"100%","src":"img/material/elevator-p.svg"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('v-tooltip',{attrs:{"right":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"dark":"","color":"red"}},on),[_vm._v(" 1 ")])]}}])},[_c('span',{staticClass:"pa-5 subtitle-1 "},[_vm._v("Механические опасности")])]),_c('v-tooltip',{attrs:{"right":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"dark":"","color":"red"}},on),[_vm._v(" 2 ")])]}}])},[_c('span',{staticClass:"pa-5 subtitle-1 "},[_vm._v("Застревание в кабине")])]),_c('v-tooltip',{attrs:{"right":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"dark":"","color":"red"}},on),[_vm._v(" 3 ")])]}}])},[_c('span',{staticClass:"pa-5 subtitle-1 "},[_vm._v("Удар дверями")])]),_c('v-tooltip',{attrs:{"right":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"dark":"","color":"red"}},on),[_vm._v(" 4 ")])]}}])},[_c('span',{staticClass:"pa-5 subtitle-1 "},[_vm._v("Термическая опасность")])]),_c('v-tooltip',{attrs:{"right":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"dark":"","color":"red"}},on),[_vm._v(" 5 ")])]}}])},[_c('span',{staticClass:"pa-5 subtitle-1 "},[_vm._v("Отравление продуктами горения")])]),_c('v-tooltip',{attrs:{"right":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"dark":"","color":"red"}},on),[_vm._v(" 6 ")])]}}])},[_c('span',{staticClass:"pa-5 subtitle-1 "},[_vm._v("Отключение электричества")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"dark":"","color":"red"}},on),[_vm._v(" 7 ")])]}}])},[_c('span',{staticClass:"pa-5 subtitle-1 "},[_vm._v("Некачественные конструкции или неправильное размещение знаков безопасности")])])],1)],1)]),_c('v-sheet',{staticClass:"d-flex justify-center align-center mx-auto mt-5",attrs:{"flat":""}},[_c('v-card',{staticClass:"d-flex justify-center",attrs:{"flat":""}},[_c('v-icon',{attrs:{"color":"red","size":"30"}},[_vm._v("mdi-cursor-pointer")]),_c('v-card-title',{staticClass:"font-weight-light"},[_vm._v(" Наводите на кнопки для изучения информации ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }